// Generated by Framer (3f003a4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["aubLLu7WS", "e6r0dtg24"];

const variantClassNames = {aubLLu7WS: "framer-v-1j66sqc", e6r0dtg24: "framer-v-2hw0wg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "aubLLu7WS", "Variant 2": "e6r0dtg24"};

const transitions = {default: {delay: 0, duration: 2, ease: [0, 0, 1, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "aubLLu7WS", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "aubLLu7WS", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1ls466 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("e6r0dtg24"), 100)
})

const onAppear69bp72 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("aubLLu7WS"), 2000)
})

useOnVariantChange(baseVariant, {default: onAppear1ls466, e6r0dtg24: onAppear69bp72})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-X7eCY", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} __perspectiveFX={false} __targetOpacity={1} className={cx("framer-1j66sqc", className)} data-border data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"aubLLu7WS"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgba(0, 0, 0, 0.3)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "dashed", "--border-top-width": "1px", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", borderTopLeftRadius: "50%", borderTopRightRadius: "50%", rotate: 0, ...style}} variants={{e6r0dtg24: {rotate: 360}}} {...addPropertyOverrides({e6r0dtg24: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-X7eCY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-X7eCY .framer-m8o6jf { display: block; }", ".framer-X7eCY .framer-1j66sqc { height: 200px; overflow: visible; position: relative; width: 200px; }", ".framer-X7eCY.framer-v-2hw0wg .framer-1j66sqc { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 200px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"e6r0dtg24":{"layout":["fixed","fixed"]}}}
 */
const FramerpRVWz2MeW: React.ComponentType<Props> = withCSS(Component, css, "framer-X7eCY") as typeof Component;
export default FramerpRVWz2MeW;

FramerpRVWz2MeW.displayName = "Dotted Spinner";

FramerpRVWz2MeW.defaultProps = {height: 200, width: 200};

addPropertyControls(FramerpRVWz2MeW, {variant: {options: ["aubLLu7WS", "e6r0dtg24"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerpRVWz2MeW, [])